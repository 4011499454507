import { createMuiTheme } from '@material-ui/core/styles';

const primaryDark = '#333333'
const secondaryDark = '#212121'
const tertiaryDark = '#31313131'
const quarternaryDark = '#2f2f2f2f'

const primaryTextColor = 'white'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: primaryDark,
      },
      secondary: {
          main: secondaryDark,
        }
      },
      overrides: {
        MuiPaper: {
          root: {
            boxShadow: 'heavy',
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: 'rgba(255, 0, 0, 0.75)',
          },
        },
        MuiAppBar: {
          root: {
            background: primaryDark,
            boxShadow: 'heavy',
          },
          colorPrimary: {
            backgroundColor: primaryDark,
          }
        },
        MuiIconButton: {
          root: {
            marginLeft: -12,
            marginRight: 20,
            color: 'white',
          }
        },
        MuiInputBase:{
          input: {
            color: primaryTextColor,
          },
        },
        MuiStepLabel: {
            root: {
                color: primaryTextColor,
                backgroundColor: '#fff',
            },
            label: {
              color: primaryTextColor,
              '&$active': {
                  color: 'white'
              },
          },
        },
        MuiStep: {
            horizontal: {
                color: primaryTextColor,
            },
        },
        MuiTable: {
            root: {
              color: primaryTextColor,
              backgroundColor: tertiaryDark,
            },
        },
        MuiTableHead: {
          root: {
            color: primaryTextColor,
            backgroundColor: tertiaryDark,
          },
      },
        MuiTableRow: {
          root: {
            color: primaryTextColor,
            backgroundColor: tertiaryDark,
          },
      },
        MuiTableCell: {
          root: {
            color: primaryTextColor,
            backgroundColor: tertiaryDark,
          },
          body: {
            color: primaryTextColor,
            backgroundColor: tertiaryDark,
          },
          head: {
            color: primaryTextColor,
            backgroundColor: tertiaryDark,
          },
          stickyHeader: {
            color: primaryTextColor,
            backgroundColor: primaryDark,
          },
      },
      MuiTablePagination: {
        toolbar: {
          color: primaryTextColor,
          backgroundColor: primaryDark,
        },
    },
    }
    },
  );

  export default theme;