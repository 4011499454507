import React, { useEffect, useState} from 'react';
import Axios from 'axios';
import CustomFooter from "../Components/CustomFooter";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import '../App.scss';


export default function TribalCouncil () {
  const [player, setPlayer] = useState('Alec White');
  const [picks, setPicks] = useState([]);
  const [week, setWeek] = useState(4);
  const [loading, setLoading] = useState(true);
  const num = 32;
  const handleChange = (e) => {
    setPlayer(e.target.value)
  }
  const players = [
    'Aidan Balboni',
    'Alec Roussos',
    'Alec White',
    'Andrew Broughton',
    'Austin Allee',
    'Austin Franchi',
    'Barron Jeter',
    'Ben Cowan',
    'Ben Katz',
    'Bill Kerrigan',
    'Brad Hondros',
    'Brendan Dunphy',
    'Brett Margulis',
    'Cam Connolly',
    'Cam Keane',
    'Chaz Stamey',
    'Chris Boland',
    'Chris DeCarolis',
    'Chris Dillon',
    'Chris Perez',
    'Conor Haughey',
    'Craver Stamey',
    'Dan Fessel',
    'Davis Womble',
    'Declan Hayes',
    'Drew London',
    'Eli Turlington',
    'Erin McClam',
    'Fred ODonnell',
    'Garrett Thomas',
    'Grant Martin',
    'Ian Brown',
    'Jack Brooks',
    'Jack Connolly',
    'Jack Dillon',
    'Jackson Eubank',
    'Jacob Roscoe',
    'Jake Wakoff',
    'Jeb Byrne',
    'Jerroll Charles',
    'John Coggins',
    'John Finch',
    'Jonathan Wheeler',
    'Jose Alvarez',
    'Liam Dillon',
    'Mac Selverian',
    'Marc Daitch',
    'Martino',
    'Martinos Brother',
    'Matt Brooks',
    'Matt Gaffney',
    'Mike Barnes',
    'Mike Tierney',
    'MJ Muldowney',
    'Owen Dugan',
    'Pat McLaughlin',
    'Patrick Sellers',
    'Peter Gilson',
    'Rufus Frost',
    'Ryan Campbell',
    'Sean Dillon',
    'Tanner Brusko',
    'Teddy Selverian',
    'Todd Block',
    'Tommy Allen',
    'Troy Waddell',
    'Tyler Desmond',
    'Tyler Sweeney',
    'Wan Pao',
    'Will Maher',
    'Will McDugald',
    'Wilson Powell'];

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    selectableRows: true,
    rowsPerPage: 10,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage
    ) => {return <CustomFooter changeRowsPerPage={changeRowsPerPage} rowsPerPage={rowsPerPage} page={page} changePage={changePage} count={count} handleChange={handleChange} player={player} players={players} />}
  };


  const columns = [
    { name: 'team', label: 'Team', width: 20 },
    { name: 'opponent', label: 'Opponent', width: 20 },
    { name: 'spread', label: 'Spread', width: 70 },
    { name: 'points', label: 'Points', width: 70 },
    { name: 'biscuit', label: 'Biscuit Score', width: 70 },
    { name: 'wins', label: 'Wins Remaining', width: 70 },
  ]

  useEffect(() => {
    const setup = () => {
      let resource = `https://m2gdphi1k7.execute-api.us-east-1.amazonaws.com/prod?player=${player}&num=${num}`
      setLoading(true)
      Axios.get(resource).then(response => {
        setPicks(response.data.picks);
        setWeek(response.data.week);
        setLoading(false);
        });
    }
    setup()
  }, [player]);

  return (
    <div className="body flex-column">
      <div className="homepage flex">
        <div className="body flex-column" style={{flexDirection: 'column',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'}}>
          { loading ? <CircularProgress color="inherit"/> : <div>
            <MUIDataTable
              title={`Best Picks for ${player} in Week ${week}`}
              data={picks}
              columns={columns}
              options={options}
            />
          </div> }

          {/*<img src={'https://dillon-survivor-21.s3.amazonaws.com/NFL+Survivor+League+2021-2022.xlsx'}/>*/}

        </div>
      </div>
    </div>
  );
}

