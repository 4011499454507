import React from 'react';
import './App.scss';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Posts from './Pages/Posts';
import NewPost from './Pages/NewPost';
import SignIn from './Pages/SignIn';
import TribalCouncil from './Pages/TribalCouncil';
import {MuiThemeProvider} from "@material-ui/core/styles";
import theme from "./Styles/Theme";
import {Paper} from "@material-ui/core";
import PageNav from "./Components/PageNav";
import PaperFooter from "./Components/PaperFooter";
import { isLogin } from './utils';

function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  )
}

const PrivateRoute = ({component: Component, ...rest}) => {
  return (

      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
          isLogin() ?
              <Component {...props} />
              : <Redirect to="/signin" />
      )} />
  );
};

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Paper className="Paperbg" elevation={0} />

          <div className="PaperbgOpaque">
            <PageNav/>
            <div className="page-content">
                <Switch>
                <Route exact path="/" component={Home} />
                <PublicRoute path="/projects" component={Projects} />
                <PublicRoute path="/posts" component={Posts} />
                <PublicRoute path="/about" component={About} />
                <PublicRoute path="/signin" component={SignIn} />
                <PrivateRoute path="/newpost" component={NewPost} />
                <PublicRoute path="/tribalcouncil" component={TribalCouncil} />
              </Switch>
            </div>
            <PaperFooter/>
          </div>
        </div>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
