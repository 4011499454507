import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { withStyles } from "@material-ui/core/styles";

const defaultFooterStyles = {};

const CustomFooter = ({count,
                        page,
                        rowsPerPage,
                        changeRowsPerPage,
                        changePage,
                        player,
                        players,
                        handleChange}) => {
    return (
      <TableFooter>
        <TableRow style={{display:'flex', width: '100%', justifyContent: 'space-between'}}>
          <TableCell style={{width: '100%'}}>
            <FormControl>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Rows
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="Player"
                onChange={(e) => changeRowsPerPage(e.target.value)}
              >
                <MenuItem value={5}>{5}</MenuItem>
                <MenuItem value={10}>{10}</MenuItem>
                <MenuItem value={16}>{16}</MenuItem>
                <MenuItem value={32}>{32}</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <FormControl>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Player
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={player}
                label="Player"
                onChange={handleChange}

              >
                {players.map(name => <MenuItem value={name}>{name}</MenuItem>)}
              </Select>
            </FormControl>

            </TableCell>
          <TableCell style={{ width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <IconButton disabled={page===0} onClick={() => {changePage(page-1)}}><ChevronLeftIcon/></IconButton>
              {page+1} of {Math.ceil(count/rowsPerPage)}
              <IconButton disabled={rowsPerPage*(page+1)>=count} onClick={() => {changePage(page+1)}}><ChevronRightIcon/></IconButton>
            </div>
          </TableCell>
        </TableRow>
      </TableFooter>
    );
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(
  CustomFooter
);
