import React, {Component, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import '../App.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PostsFunc() {
  let query = useQuery();
  let postTitle = query.get("name");


  return (
    <div className="body">
        <h2>Posts</h2>
    </div>
  );

}

export default class Posts extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
        <PostsFunc/>
    );
  }
}