import React  from 'react';
import { useHistory } from 'react-router-dom';
import '../App.scss';
import {DisplayShowcaseProject, GetRandomProject} from "./Projects";


export default function Home () {
    let history = useHistory();

  return (
    <div className="body">
        <div className="homepage flex">
            <div className="homepage-bio">
                <h3 className='title' >Welcome.</h3>
                <p>I am a Boston based software developer.</p>
                <p>This is where I post my <span className="link" onClick={() => history.push("/projects")}>projects.</span></p>
            </div>
            <div className='showcase-project-container'>
                <h3 className='title'>Project Showcase</h3>
                {DisplayShowcaseProject(GetRandomProject())}
            </div>
        </div>
    </div>
  );
}
