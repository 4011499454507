import React from 'react';
import { useHistory } from "react-router-dom";
import '../App.scss';
import { Button } from '@material-ui/core';
import useWindowDimensions from '../dimens';

function PageNav() {
    const history = useHistory()
    const { height, width } = useWindowDimensions()
        if (height > width ) { return (
            <div className="nav">
                <div className="background">
                    <div className="container">
                        <div className="title-container">
                            <h1 className="title">Alec White</h1>
                        </div>
                        <div style={{maxWidth: '90vw', paddingTop: '4vh', paddingLeft: '3vw'}}>

                            <Button className="NavButton" onClick={()=> {history.push('/')}}>Home</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/projects')}}>Projects</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/about')}}>About</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/posts')}}>Posts</Button>
                            <Button className="NavButton" onClick={() => window.location.href='https://resume-alecw.s3.amazonaws.com/Resume_White_Alexander_8_20.pdf'}>Resumé</Button>

                        </div>

                    </div>
                </div>
            </div>)
        } else {
            return (<div className="nav">
                <div className="background">
                    <div className="container">
                        <div style={{maxWidth: '30vw'}}>

                            <Button className="NavButton" onClick={()=> {history.push('/')}}>Home</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/projects')}}>Projects</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/about')}}>About</Button>
                            <Button className="NavButton" onClick={()=> {history.push('/posts')}}>Posts</Button>
                        </div>
                        <div className="title-container">
                            <h1 className="title">Alec White</h1>
                        </div>
                        <div style={{maxWidth: '30vw'}}>
                            <Button className="NavButton" onClick={() => window.open('https://resume-alecw.s3.amazonaws.com/Resume_White_Alexander_8_20.pdf')}>Resumé</Button>
                        </div>
                    </div>
                </div>
            </div>)
        }
}

export default PageNav;