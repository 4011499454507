import React, { Component } from 'react';
import '../App.scss';
import drawtfLogo from "../Assets/project-logos/drawtf_logo.png";
import mazeOrBowieLogo from "../Assets/project-logos/maze-or-bowie.png";
import pepperstacheLogo from "../Assets/project-logos/pepperstache-logo.png";
import tribalCouncilLogo from "../Assets/project-logos/tribal-council-logo.png";

const PersonalProjects = [{link: 'https://drawtf.com', shortDescription: 'Social Drawing Game',
    longDescription:'A telephone and pictionary derived game. Given someone else\'s drawing guess what the topic is. Given a topic draw your interpretation.',
    logo:drawtfLogo, name:'DRAWTF'},{link: '/tribalcouncil', shortDescription: 'NFL Survivor Fantasy Game Aid',
    longDescription:'Helps determine the best value pick in an NFL survivor pool given past picks, point value, power rankings, and upcoming schedule',
    logo:tribalCouncilLogo, name:'Tribal Council'},
    {link: 'https://pepperstache.com', shortDescription: 'Hot Sauce Brand', longDescription:'Homemade vinegar and balcony grown ghost and jalapeno peppers with some groovy branding.',
        logo:pepperstacheLogo, name:'pepperstache.'},
    {link: 'https://mazeorbowie.com', shortDescription: 'Quiz Game', longDescription:'Listen to the intro of the song and guess if it\'s Maze or David Bowie by Phish. It\'s harder than you might think!', logo:mazeOrBowieLogo, name: 'Maze or Bowie'}]

export const GetRandomProject = () => {
    const randomIndex = Math.floor(Math.random() * PersonalProjects.length);
    return PersonalProjects[randomIndex]
}

const DisplayProject = (props) => {
    const {logo, link, name, shortDescription, longDescription} = props;
    return (
        <div className="project">
        <img src={logo} onClick={() => window.open(link)}/>
        <a className='title-link' href={link}><span className="link">{name}</span></a>
        <p className='short-description'>{shortDescription}</p>
        <p className='long-description'>{longDescription}</p>
    </div>)
}

export const DisplayShowcaseProject = (props) => {
    const {logo, link, name, shortDescription, longDescription} = props;
    return (<div className="showcase-project">
        <img src={logo} onClick={() => window.open(link)}/>
        <a className='title-link' onClick={() => window.open(link)}><span className="link">{name}</span></a>
        <p className='short-description'>{shortDescription}</p>
        <p className='long-description'>{longDescription}</p>
    </div>)
}

export default class Projects extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render () {
  return (
    <div className="body flex-column">
        <h2>Projects</h2>
        <h3>Selected Personal Projects</h3>
        <div className="wrapper flex">
            {PersonalProjects.map(item => {return DisplayProject(item)})}
        </div>
        <h3>Work</h3>
      <div style={{display:'flex', flexDirection: 'row'}}>
        <div>
          <h4>WHOOP</h4>
          <ul>
            <li>Full Stack Developer</li>
            <li>Growth - Monetization Team</li>
            <li>Handling membership post acquisition</li>
            <li>Java + Node.js + React + Angular + SQL</li>
            <li>Stripe & Avalara AvaTax</li>
          </ul>
        </div>
          <div>
            <h4>Cipher Tech</h4>
            <ul>
              <li>Forensic Software Developer</li>
              <li><span className="redacted">██████████████</span></li>
              <li><span className="redacted">███████████████</span></li>
              <li><span className="redacted">█████████████</span></li>
              <li><span className="redacted">████████████</span></li>
            </ul>
          </div>
      </div>
    </div>
  );
  }
}
