import React from 'react';
import '../App.scss';
import { SocialIcon } from 'react-social-icons';

function PaperFooter() {
    return (
        <div className="footer">
            <div className="item">
                <p onClick={() => window.open("mailto:alec@alecw.co")}>+ alec@alecw.co</p>
                <div className="social">
                    <SocialIcon style={{margin: 10}} url={'https://www.linkedin.com/in/alexander-alec-white-8388781a9/'} />
                    <SocialIcon style={{margin: 10}} url={'https://github.com/akwhite44'} />
                </div>
            </div>

    </div>)
}

export default PaperFooter;