import React, {Component, useState} from 'react';
import '../App.scss';
import { Text, TextArea, Button } from "@blueprintjs/core";


function GetTimestamp() {
    return new Date().getTime()
}

function CreatePost() {
    const [textContent, setTextContent] = useState('')
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')

    const onTitleChange = (event) => {setTitle(event.value)};
    const onSubtitleChange = (event) => {setSubtitle(event.value)};
    const onContentChange = (event) => {setTextContent(event.value)};



    return (
        <div className="body">
            <h2>Create a New Post</h2>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h3>Title</h3>
                <TextArea style={{width: '60vw', height: '3vh'}} value={title} onChange={onTitleChange}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h3>Subtitle</h3>
                <TextArea style={{width: '60vw', height: '3vh'}} value={subtitle} onChange={onSubtitleChange}/>
            </div>
            <h3>Category</h3>
            <div >
                <h3>Content</h3>
                <TextArea style={{width: '80vw', height: '80vh'}} fill={true} onChange={onContentChange} value={textContent} />
            </div>
            <div>
                <Button>Post</Button>
            </div>
        </div>
    );
}

export default class NewPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render () {
        return (<CreatePost/>)
    }
}