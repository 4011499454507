import React, { Component } from 'react';
import '../App.scss';


export default class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render () {
  return (
    <div className="body flex-column">
        <h2>About me</h2>
        <h3>Technical Skills</h3>
        <div className="wrapper flex">
        <div>
            <h4>Types of Development</h4>
            <ul>
                <li>Backend</li>
                <li>Frontend</li>
                <li>Mobile</li>
                <ul>
                    <li>Android</li>
                    <li>iOS</li>
                </ul>
                <li>Reverse Engineering</li>
            </ul>
        </div>
      <div>
          <h4>Languages</h4>
          <ul>
              <li>Proficient</li>
              <ul><li>Go</li>
                  <li>java</li>
                  <li>Python</li>
                  <li>Typescript</li>
                  <li>JavaScript</li>
                  <li>C++</li>
                  <li>C</li></ul>
                  <li>Cursory experience</li>
                  <ul>
                      <li>C#</li>
                      <li>Dart</li>
                      <li>Java</li>
                      <li>Clojure</li>
                      <li>Kotlin</li>
                      <li>Swift</li>
                  </ul>

          </ul>
      </div>
        <div>
          <h4>Other Tools</h4>
          <ul>
              <li>Git</li>
              <li>Redis</li>
              <li>Docker</li>
          </ul>
          <ul>
            <li>Stripe</li>
            <li>Avalara Avatax</li>
            <li>Data Dog</li>
            <li>Segment</li>
            <li>Amplitude</li>
            <li>Kibana</li>
          </ul>
        </div>
        <div>
            <h4>Databases</h4>
            <ul>
                <li>Relational Databases</li>
                <ul>
                    <li>SQL Server</li>
                    <li>Postgres</li>
                </ul>
                <li>NoSQL</li>
                <ul>
                    <li>DynamoDB</li>
                </ul>
                <li>Time Series Databases</li>
                <ul>
                    <li>Prometheus</li>
                </ul>
            </ul>
        </div>
        <div>
            <h4>Frameworks</h4>
            <ul>
                <li>React</li>
                <li>Redux</li>
                <li>Sass</li>
                <li>Next.js</li>
                <li>Flutter</li>
                <li>React Native</li>
            </ul>
        </div>
        <div>
            <h4>Deployed applications in</h4>
            <ul>
                <li>Amazon Web Services</li>
                <li>Low connectivity environments</li>
            </ul>
        </div>
        <div>
            <h4>Other helpful skills</h4>
            <ul>
                <li>Adobe Photoshop</li>
                <li>Adobe Illustrator</li>
            </ul>
        </div>
        </div>
    </div>
  );
  }
}
