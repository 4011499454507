import React, {Component, useState} from 'react';
import '../App.scss';
import {Button, Input, TextField} from "@material-ui/core";


function SignInFunc() {



    return (
        <div style={{textAlign: 'left', display: 'flex', flexDirection: 'column', left: '50%',}}>
            <Input style={{width: '20rem', alignSelf: 'center'}}></Input>
            <Input style={{width: '20rem', alignSelf: 'center'}}></Input>
            <Button style={{width: '20rem', alignSelf: 'center'}}>Login</Button>
        </div>
    );
}

export default class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render () {
        return (<SignInFunc/>)
    }
}